<script lang="ts" setup>
import type { HomeJackpots } from "@/types";

const DEFAULT_INCREMENT_STEP = 0.15;
const DEFAULT_INCREMENT_INTERVAL = 550;

const props = defineProps<{
	jackpots?: HomeJackpots;
}>();

const { t } = useT();
const prepareImgUrl = useImage();
const { isSweepStakes } = useSwitchMode();

const intervalId = ref<ReturnType<typeof setInterval>>();
const amount = ref(0);
const arraybleAmount = ref<string[]>(["0"]);

const bgImage = `url(${prepareImgUrl(`/nuxt-img/jackpot/jackpot-bg.jpg`, {
	format: "avif",
	height: 300
})})`;
const bgImageMobile = `url(${prepareImgUrl(`/nuxt-img/jackpot/jackpot-bg-mob.jpg`, {
	format: "avif",
	height: 304
})})`;

const shouldCounterDisplay = computed(() => arraybleAmount.value?.some((num) => num !== "0"));

const totalWinAmount = computed(() => {
	const values = props.jackpots?.[isSweepStakes.value ? "USD" : "TRN"]?.values ?? [];
	return values.reduce((acc, item) => acc + (+item.amount ?? 0), 0) * 100;
});

const incrementValue = () => {
	amount.value += DEFAULT_INCREMENT_STEP;
	if (isSweepStakes.value) {
		arraybleAmount.value = numberFormat(amount.value, { minimumFractionDigits: 2 }).split("").slice(0, -3);
		return;
	}
	arraybleAmount.value = numberFormat(amount.value * 100).split("");
};

watch(isSweepStakes, () => {
	amount.value = totalWinAmount.value;
});

onMounted(() => {
	amount.value = totalWinAmount.value;
	intervalId.value = setInterval(incrementValue, DEFAULT_INCREMENT_INTERVAL);
});
onUnmounted(() => clearInterval(intervalId.value));
</script>
<template>
	<div v-if="shouldCounterDisplay" class="jackpot">
		<AText variant="tampa" :modifiers="['bold']">
			{{ t("funrize jackpot") }}
		</AText>
		<MPrizeFund
			:variant="isSweepStakes ? 'entries' : 'coins'"
			:img="`/nuxt-img/cards/${isSweepStakes ? 'entries' : 'coins'}.png`"
			:icon-size="40"
		>
			<AText variant="taiz" :modifiers="['bold', 'center']" class="amount" as="div">
				<TransitionGroup name="counter">
					<span
						v-for="(item, index) in arraybleAmount"
						:key="item + index"
						:class="['amount-item', { 'is-separator': isNaN(parseInt(item)) }]"
					>
						{{ isNaN(parseInt(item)) ? "," : item }}
					</span>
				</TransitionGroup>
			</AText>
		</MPrizeFund>
	</div>
</template>
<style scoped lang="scss">
.counter {
	&-enter-from,
	&-leave-to {
		opacity: 0;
	}

	&-enter-from {
		transform: translateY(40px);
	}

	&-leave-active {
		position: absolute;
	}

	&-leave-to {
		transform: translateY(-40px);
	}
}

.jackpot {
	height: 150px;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: gutter(1);
	background: v-bind(bgImage) 80% 50% / cover no-repeat;

	@include media-breakpoint-down(md) {
		height: 152px;
		padding-left: gutter(2);
		align-items: flex-start;
		background-image: v-bind(bgImageMobile);
	}

	&:deep(.prize) {
		.amount {
			position: relative;
			overflow: hidden;

			&-item:not(.is-separator) {
				transition: all 0.55s ease;
				display: inline-block;
				width: 26px;
				text-align: center;
			}
		}

		@include media-breakpoint-down(md) {
			gap: gutter(0.25);

			img {
				width: 20px;
				height: 20px;
			}

			.amount {
				font-size: 24px;
				line-height: 21px;

				&-item:not(.is-separator) {
					width: 16px;
				}
			}
		}
	}
}
</style>
